<template>
  <div class="grid">
    <loading :active="isLoadingModel" :can-cancel="true" color="#274461" :is-full-page="fullPage"></loading>
    <Toast />
    <div class="col-12 sticky">
      <div id="custom_card" class="card">
        <div class="grid justify-content-between">
          <div class="col-12 md:col-6">
            <div class="flex flex-wrap align-items-center justify-content-center md:justify-content-start md:mb-0 mb-3">
              <p style="color: #6b719b; font-size: 20px; font-weight: 800">
                {{ $t('bill_master') }}
              
                <!-- {{ this.bill_dropdownItem }}
                {{ this.status_dropdownItem }} -->
              </p>
            </div>
          </div>
          <div class="col-12 md:col-6">
            <div class="flex flex-wrap align-items-center justify-content-end md:justify-content-end md:mb-0 mb-3">
              <Button iconPos="right" :label="$t('add')" v-tooltip.bottom="$t('add')" icon="pi pi-plus" @click="openNew"
                size="small" style="
                    box-shadow: 0 2px 6px rgba(0, 176, 176, 0.549);
                    border-color: #00b0b0 !important;
                    background-color: #00b0b0 !important;
                  " />
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="col-12">
      <div class="card" id="custom_card">
        <div class="grid p-fluid">
          <div class="col-12 md:col-12">
            <div class="p-fluid formgrid grid">
              <!-- <div class="field col-12 md:col-2">
                <span class="p-float-label">
                  <InputText format="text" v-model="search_filter" @input="search(1)">
                  </InputText>
                  <label>{{ $t('search_bill') }}</label>
                </span>
              </div> -->
              <div class="field col-12 md:col-2">
                <span class="p-float-label">
                  <Dropdown id="bill_type" v-model="bill_typedropdown" :options="bill_dropdownItems"   :value="bill_dropdownItems"  optionLabel="name" 
                    filter v-on:change="search(1)"></Dropdown>
                  <label for="bill_type"> {{ $t('bill_type') }}</label>
                </span>
              </div>
              <div class="field col-2 md:col-2">

                <Button icon="pi pi-refresh" v-tooltip.bottom="$t('refresh')" @click="search(2)" class="p-button-danger"
                  style=" box-shadow: 0 2px 6px #fd9b96;
                    border-color: #fc544b !important;
                    background-color: #fc544b !important;" />
              </div>
            </div>
          </div>
        </div>

        <DataTable :loading="loading" ref="dt" :lazy="true" :totalRecords="totalRecords" :paginator="true"
          :value="products" v-model:selection="selectedProducts" :dataKey="columns[0]" :rows="limit" :filters="filters"
          @page="onPage($event)"
          paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
          :rowsPerPageOptions="PerPageOptions" currentPageReportTemplate="Showing {first} to {last} of {totalRecords}"
          responsiveLayout="scroll" style="font-size: 12px" :rowHover="true" showGridlines>
          <template #empty>
            <b style="text-align: center">{{ $t('no_rec') }}</b>
          </template>
          <template #header>

            <div style="text-align: right; font-weight: bold">
              <span>{{ $t('total_bill') }}:</span> {{ totalRecords }}
            </div>
          </template>
          <!-- <Column  header="Sr.No" style="min-width: 5rem">
              <template #body="{ data }">
                {{ 1 }}
              </template>
            </Column> -->
          <Column :header="$t('srno')" style="min-width: 5rem;text-align: center;">
            <template #body="{ index }">
              <span v-if="page_no == 1">{{ +index + +1 }}</span>
              <span v-else>{{ (+index + 1) + limit * (page_no - 1) }}</span>
            </template>
          </Column>
          <Column :header="$t('ref_no')" style="min-width: 5rem">
            <template #body="{ data }">
              {{ data.reference_no }}
            </template>
          </Column>
          <Column :header="$t('year')" style="min-width: 5rem">
            <template #body="{ data }">
              {{ data.year }}
            </template>
          </Column>
          <Column :header="$t('title')" style="min-width: 5rem">
            <template #body="{ data }">
              {{ data.title }}
            </template>
          </Column>
          <Column :header="$t('bill_type')" style="min-width: 5rem">
            <template #body="{ data }">
              {{ data.bill_type }}
            </template>
          </Column>

          <Column :header="$t('amount')" style="min-width: 5rem">
            <template #body="{ data }">
              {{ data.amount }}
            </template>
          </Column>
          <Column :header="$t('mob_no')" style="min-width: 5rem">
            <template #body="{ data }">
              {{ data.mobile_no }}
            </template>
          </Column>
          <Column :header="$t('photo')" style="min-width: 5rem">
            <template #body="{ data }">
              <img v-if="data.attachments" :src="data.attachments" alt="Photo"
                style="width: 50px; height: 50px; object-fit: cover; border-radius: 5px;" />
            </template>
          </Column>
          <!-- <Column  :header="$t('photo')" style="min-width: 5rem">
              <template #body="{ data }">
                {{ data.attachments }}
              </template>
            </Column> -->
          <Column :header="$t('status')" style="min-width: 5rem">
            <template #body="{ data }">
              {{ data.status }}
            </template>
          </Column>



          <Column :header="$t('description')" :field="columns[3]" style="min-width: 5rem">
            <template #body="{ data }">
              {{ data.description }}
            </template>
          </Column>


          <!-- <Column  :header="$t('view')" style="min-width: 5rem">
              <template #body="{ data }">
                {{  }}
              </template>
            </Column> -->
          <Column :header="$t('action')" headerStyle="min-width:10rem;">
            <template #body="slotProps">
              <Button v-if="slotProps.data.status_id != 4" icon="pi pi-pencil" title="$t('edit_client')"
                v-tooltip.bottom="$t('edit')" class="p-button-rounded p-button-success mr-2"
                @click="editProduct(slotProps.data)" style="
                      box-shadow: 0 2px 6px #006b00;
                      border-color: #006b00 !important;
                      background-color: #006b00 !important;
                    " />
              <Button icon="pi pi-eye" title="$t('view')" v-tooltip.bottom="$t('view')"
                class="p-button-rounded p-button-info mr-2" @click="viewProduct(slotProps.data)" style="
                      box-shadow: 0 2px 6px #ffc107;
                      border-color: #07C1FF !important;
                      background-color: #07C1FF !important;
                    " />
              <Button v-if="slotProps.data.status_id != 4" icon="pi pi-trash" v-tooltip.bottom="$t('delete')"
                class="p-button-rounded p-button-warning mt-2" @click="confirmDeleteProduct(slotProps.data)" style=" box-shadow: 0 2px 6px #fd9b96;
                    border-color: #fc544b !important;
                    background-color: #fc544b !important;" />

            </template>
          </Column>
        </DataTable>
      </div>
    </div>
   


    <Dialog v-model:visible="viewDialog" :header="$t('view_details')" :breakpoints="{ '960px': '75vw' }"

          :style="{ width: '40vw', borderRadius: '12px', padding: '20px', boxShadow: '0px 4px 10px rgba(0, 0, 0, 0.2)', backgroundColor: '#f9f9f9' }"
      :modal="true">

      <div class="custom-container">


        <div class="p-fluid formgrid grid">

           <div class="field col-12 md:col-3 " >

            <b>{{ $t('ref_no') }}:</b> 

          </div>
          <div class="field col-12 md:col-3 value" >
            {{ selectedProduct.reference_no }}
           

          </div>
          
          <div class="field col-12 md:col-3 " >
            <b>{{ $t('year') }}:</b>
          </div>
          <div class="field col-12 md:col-3 value" >
            {{ selectedProduct.year }}
            
          </div>
          <div class="field col-12 md:col-3" >
            <b>{{ $t('mob_no') }}:</b>
           
          </div>
          <div class="field col-12 md:col-3 value" >
             {{ selectedProduct.mobile_no }}
          </div>
          <div class="field col-12 md:col-3" >
            <b>{{ $t('bill_type') }}:</b>
          
          </div>
          <div class="field col-12 md:col-3 value" >
            {{ selectedProduct.bill_type }}
          
          </div>

          <div class="field col-12 md:col-3" >
            <b>{{ $t('title') }}:</b>
          
          </div>
          <div class="field col-12 md:col-9 value" >
            {{ selectedProduct.title }}
           
          </div>
          <div class="field col-12 md:col-3" >
            <b>{{ $t('description') }}:</b>
           
          </div>
          <div class="field col-12 md:col-9 value" >
           
             {{ selectedProduct.description }}
          </div>

          <div class="field col-12 md:col-3 " >
            <b>{{ $t('amount') }}:</b>
           
          </div>
          <div class="field col-12 md:col-3 value" >
            {{ selectedProduct.amount }} 
          </div>
          <div class="field col-12 md:col-3" >
            <b>{{ $t('status') }}:</b>
          
          </div>
          <div class="field col-12 md:col-3 value" style="margin-top: 1%;">
            {{ selectedProduct.status }}
          
          </div>

         

          <div class="field col-12 md:col-3 "><b>{{ $t('Photo') }}:</b></div>
      <div class="field col-12 md:col-9 ">
        <img v-if="selectedProduct.attachments" :src="selectedProduct.attachments"
          alt="Photo" class="preview-image"/>
      </div>
         

        </div>
      </div>
    </Dialog>




    <!-- hhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhhh -->
    <Dialog v-model:visible="productDialog" :breakpoints="{ '960px': '75vw' }" :style="{ width: '35vw' }"
      :header="isEditMode ? $t('edit_bill') : $t('add_bill')" :modal="true" position="top">
      <div class="col-12">
        <div class="p-fluid formgrid grid" style="margin-top: 2%">
          <div class="field col-12 md:col-12" style="margin-top: 1%">
          
            
            <span class="p-float-label">
              <Dropdown id="bill_type" v-model="bill_dropdownItem" :options="bill_dropdownItems" optionLabel="name"
                :disabled="view" :class="{ 'p-invalid': submitted && !bill_dropdownItem }"></Dropdown>

              <label for="bill_type">{{ $t('bill_type') }}</label>
            </span>
          </div>
       
          <div class="field col-12 md:col-12" style="margin-top: 1%">
            <span class="p-float-label">
              <!-- <InputText id="year" type="number" v-model="product.year"  :disabled="view"  @input="enforceMaxLength" @keypress="validatePhoneInput" /> -->
              <Calendar 
                  id="year" 
                  v-model="product.year" 
                  view="year" 
                  dateFormat="yy" 
                  :disabled="view"
                   @keypress="validatePhoneInput"
                  :manualInput="false"
                />
              <label for="year">{{ $t('year') }}</label>
            </span>
          </div>
          <div class="field col-12 md:col-12" style="margin-top: 1%">
            <span class="p-float-label">
              <InputText id="reference_no" type="text" v-model="product.reference_no" :disabled="view"/>
              <label for="reference_no">{{ $t('ref_no') }}</label>
            </span>
          </div>
          <div class="field col-12 md:col-12" style="margin-top: 1%">
            <span class="p-float-label">
              <InputText id="ticket_title" type="text" v-model="product.title" :disabled="view" />
              <label for="name">{{ $t('title') }}</label>
            </span>
          </div>
          <div class="field col-12 md:col-12" style="margin-top: 1%">
            <span class="p-float-label">
              <InputText id="mobile_number" type="tel" v-model="product.mobile_no" @keypress="validatePhoneInput"
                maxlength="10" :disabled="view" />
              <label for="mobile_number">{{ $t('mob_no') }}</label>
            </span>
          </div>
          <div class="field col-12 md:col-12" style="margin-top: 1%">
            <span class="p-float-label">
              <InputText id="amount" type="number" v-model="product.amount" :disabled="view" />
              <label for="amount">{{ $t('amount') }}</label>
            </span>
          </div>


          <div class="field col-12 md:col-12" style="margin-top: 1%">
            <h6 style="color: #6b719b; font-size: 15px; font-weight: 400">
              {{ $t('description') }} :
            </h6>
            <Editor v-model="product.description" editorStyle="height: 100px" :disabled="view" />
          </div>

         
         
          <div class="field col-12 md:col-12" style="margin-top: 2%">
            <span class="p-float-label">
              <Dropdown id="status" v-model="status_dropdownItem" :options="status_dropdownItems" optionLabel="name"
                filter :disabled="view" :class="{ 'p-invalid': submitted && !status_dropdownItem }">
              </Dropdown>
              <label for="status">{{ $t('status') }}</label>
            </span>
          </div>
          

          <div class="field col-12 md:col-12" style="margin-top: 1%">
            <FileUpload class="p-button-primary" mode="basic" name="model[]" :auto="true" :custom-upload="true"
              :disabled="view" :choose-label="$t('choose_image')" @uploader="handleFileUpload($event)" style="
                  box-shadow: 0 2px 6px rgba(0, 176, 176, 0.549);
                  border-color: #00b0b0 !important;
                  background-color: #00b0b0 !important;
                " />

          </div>
          <div class="field col-12 md:col-3" style="margin-top: 1%">
            <img :src="product.attachments" :alt="product.attachments" v-if="product.attachments" class="shadow-2"
              width="70" />

          </div>
          <div v-if="!view && file " class="field col-12 md:col-2">
              <Button class="p-button-danger" mode="basic" @click="cancelFileUpload()">
                <i class="pi pi-trash" style="margin-right: 5px"></i>
              </Button>
            </div>
          <!-- <div v-if="!view" class="field col-12 md:col-2">

            <Button class="p-button-danger" mode="basic" :disabled="!file || file.length === 0"
              @click="cancelFileUpload()">
              <i class="pi pi-trash" style="margin-right: 5px"></i>
             
            </Button>
          </div> -->
        



        </div>
      </div>

      <template #footer v-if="!view">
        <Button :label="$t('save')" @click="save_bill" icon="pi pi-check" class="p-button-success" />
      </template>
    </Dialog>

    <Dialog v-model:visible="deleteProductDialog" :style="{ width: '450px' }" :header="$t('Confirm')" :modal="true">
      <div class="flex align-items-center justify-content-center">
        <i class="pi pi-exclamation-triangle mr-3" style="font-size: 2rem" />
        <span v-if="product">{{ $t('delete_msg') }}<b>{{ product.name }}</b></span>
      </div>
      <template #footer>
        <Button :label="$t('no')" icon="pi pi-times" class="p-button-text" @click="deleteProductDialog = false" />
        <Button :label="$t('yes')" icon="pi pi-check" class="p-button-text" @click="deletefaq" />
      </template>
    </Dialog>
  </div>
</template>
<script>
import { FilterMatchMode } from "primevue/api";
import ProductService from "../service/ProductService";

import apis from "@/apis";
import axios from "axios";
export default {
  data() {
    return {
      search_filter: "",
      user_key: "",
      page_no: 1,
      billType: "",
      bill_dropdownItems:[],
      
      selectedProduct: {},
      filename: "",
      attachments: "",
      bill_typedropdown: "",
      isEditMode: false,
      Mode: 3,
      view: false,
      isLoading: false,
      reference_no: "",
      status_selected: "",
      title: "",
      mobile_no: "",
      description: "",
      year: "",
      amount: "",
      bill_type: "",

      PerPageOptions: [10, 50, 100],
      isLoadingModel: false,
      loading: false,
      status_dropdownItems:[],
     
      status_dropdownItem: null,
      bill_dropdownItems:[],
      bill_dropdownItem: "",
      fullPage: true,
      products: null,
      productDialog: false,
      deleteProductDialog: false,
      deleteProductsDialog: false,
      lazyParams: {},
      product: {},
      selectedProducts: null,
      filters: {},
      offset: 1,
      limit: 10,
      columns: [],
      pageno: 1,
      submitted: false,
      totalRecords: 0,
      id: "",
      file_data: {
        filePath: '',
        contentType: ''
      }
    };
  },
  productService: null,
  async created() {
    this.productService = new ProductService();
   this.getBillTypelist();
   this.getStatusBillMaster();
    this.user_key = localStorage.getItem("user_type");
  },
  async mounted() {
    this.id = this.$route.params.id;
    //this.attachments=this.product.attachments;
    this.lazyParams = {
      first: 0,
      rows: this.limit,
      sortField: null,
      sortOrder: null,
      filters: this.filters,
    };
    if (this.totalRecords == 0) {
      await this.get_count();
    }
    await this.get_list();
  },
  watch: {
    async $route() {
      this.products = [];
      this.columns = [];
      this.totalRecords = 0;
      this.id = this.$route.params.id;
      if (this.id) {
        await this.get_count();
        this.get_list();
      }
    },
  },
  watch: {
    currentLocale() {

      this.getBillTypelist(this.currentLocale);
      this.getStatusBillMaster(this.currentLocale);
      this.get_list(this.currentLocale);
      this.get_count()
    },

  },
  computed: {
    currentLocale() {

      return this.$i18n.locale;
    }
  },
  methods: {
    YY_formatYear(date_parm) {
    if (date_parm == null) {
        return "";
    } else {
        let date = new Date(date_parm);
        let yy = date.getFullYear().toString().slice(-4); // Extract last two digits
        return yy;
    }
},

  //   enforceMaxLength() {
    
  //   if (this.product.year.length > 4) {
  //     this.product.year = this.product.year.slice(0, 4);
  //   }
  // },
  async getBillTypelist() {
    var data = {
      //client_id: "65a4f82f6177e69880ece5d6",
     
      lang:this.$i18n.locale
     
      
      
    };
    var temp = "";
    // this.isLoadingModel = true;
    var promise = apis.getBillTypeMaster(data);
    promise.then((response) => {
      // this.isLoadingModel = false;
      this.bill_dropdownItems=[];
      temp = response.data.data;
      for (let i = 0; i < temp.length; i++) {
         
            this.bill_dropdownItems.push({name:temp[i].name,value:temp[i].name});
          
        }
    });
  },
  async getStatusBillMaster() {
    var data = {
      
     
      lang:this.$i18n.locale
     
      
      
    };
    var temp = "";
   
    var promise = apis.getStatusBillMaster(data);
    promise.then((response) => {
      // this.isLoadingModel = false;
      this.status_dropdownItems=[];
      temp = response.data.data;
      for (let i = 0; i < temp.length; i++) {
         
            this.status_dropdownItems.push({name:temp[i].name,value:temp[i].status_id});
          
        }
    });
  },
    cancelFileUpload() {


      this.file_data = {
        filePath: '',
        contentType: ''
      };

      this.product.attachments = "";

      this.filename = '';
    },
    async date_time_prefix() {
      // Get the current date and time
      const currentDateTime = new Date();
      // Format the date as YYYY_MM_DD
      const formattedDate = currentDateTime
        .toISOString()
        .split("T")[0]
        .replace(/-/g, "_");
      // Format the time as HH_mm_ss
      const formattedTime = currentDateTime
        .toTimeString()
        .split(" ")[0]
        .replace(/:/g, "_");
      // Construct the final string
      const resultString = `${formattedDate}_${formattedTime}`;
      return resultString;
    },
    async handleFileUpload(event) {
      var fileUp = event.files[0];
      var file = fileUp;

      var validImageTypes = ["image/jpeg", "image/png", "image/gif"];
      if (!validImageTypes.includes(file.type)) {
        this.$toast.add({
          severity: "error",
          summary: this.$t('invalid_file'),
          detail: this.$t('onlyImageFilesAllowed'),
          life: 3000,
        });
        return; // Stop the upload process
      }
      this.file = file;

      var prfeix = await this.date_time_prefix();
      var filename = prfeix + "_" + file.name;
      this.file_data = {
        filePath: filename,
        contentType: file.type,
      };
      var promise = apis.upload_to_AWS(this.file_data);
      promise.then((response) => {
        axios
          .put(response.data.data, file, {
            headers: {
              "Access-Control-Allow-Origin": "*",
              "Content-Type": file.type,
            },
          })
          .then((response) => {
            this.attachments =
              "https://eticket-docs.s3.ap-south-1.amazonaws.com/" + filename;
            this.product.attachments = this.attachments;
          });
      });
    },
    validatePhoneInput(event) {
      const key = event.keyCode || event.which; // Use keyCode or which
      if (key < 48 || key > 57) {
        event.preventDefault(); // Prevent non-numeric characters
      }
    },
    async save_bill() {
      if (!this.bill_dropdownItem) {
        this.$toast.add({
          severity: "error",
          summary: this.$t("error"),
          detail: this.$t("enter_bill_type"),
          life: 3000,
        });
        return false;
      }
      if (!this.product.year) {
        this.$toast.add({
          severity: "error",
          summary: this.$t('error'),

          detail: this.$t("enter_year"),
          life: 3000,
        });
        return false;
      }
      if (!this.product.reference_no) {
        this.$toast.add({
          severity: "error",
          summary: this.$t('error'),
          // detail: "Please Enter Description",
          detail: this.$t("enter_reference_no"),
          life: 3000,
        });
        return false;
      }
      
      var referenceNoPattern = /^[A-Za-z0-9-]+$/;
        if (!referenceNoPattern.test(this.product.reference_no)) {
          this.$toast.add({
            severity: "error",
            summary: this.$t("error"),
            detail: this.$t("invalid_reference_no"), 
            life: 3000,
          });
          return false;
        }
      if (!this.product.title) {
        this.$toast.add({
          severity: "error",
          summary: this.$t('error'),
          // detail: "Please Enter Description",
          detail: this.$t("enter_title"),
          life: 3000,
        });
        return false;
      }

      if (this.product.mobile_no.length > 10 || this.product.mobile_no.length < 10) {
        this.$toast.add({
          severity: "error",
          summary: this.$t('error'),
          //detail: "Please Enter Valid Mobile Number",
          detail: this.$t("mobile_invalid_length_error"),
          life: 3000,
        });
        return false;
      }
      if (this.product.mobile_no.length !== 10) {
        // Condition: Mobile number length is not 10
        this.$toast.add({
          severity: "error",
          summary: this.$t('error'),
          // detail: "Mobile number must be exactly 10 digits.",
          detail: this.$t("mob_tendigit"),
          life: 3000,
        });
        return false;
      }
      if (!/^\d+$/.test(this.product.mobile_no)) {
        // Condition: Mobile number contains non-numeric characters
        this.$toast.add({
          severity: "error",
          summary: this.$t('error'),
          // detail: "Mobile number must contain only digits.",
          detail: this.$t("mobile_numeric_error"),
          life: 3000,
        });
        return false;
      }
      if (/^0+$/.test(this.product.mobile_no) || /^0/.test(this.product.mobile_no)) {
        // Condition: Mobile number contains all zeros or starts with zero
        this.$toast.add({
          severity: "error",
          summary: this.$t('error'),

          detail: this.$t("mobile_zero_error"),
          life: 3000,
        });
        return false;
      }
      if (!this.product.amount) {
        this.$toast.add({
          severity: "error",
          summary: this.$t("error"),
          detail: this.$t("enter_amount"),
          life: 3000,
        });
        return false;
      }
      if (!this.product.description) {
        this.$toast.add({
          severity: "error",
          summary: this.$t('error'),

          detail: this.$t("enter_description"),
          life: 3000,
        });
        return false;
      }
     
      if (!this.status_dropdownItem) {
        this.$toast.add({
          severity: "error",
          summary: this.$t("error"),
          detail: this.$t("enter_status"),
          life: 3000,
        });
        return false;
      }

     

      var stripHtmlTags = (html) => {
        return html.replace(/<\/?[^>]+(>|$)/g, ""); // Removes all HTML tags
      };

      let details =
      {
        "reference_no": this.product.reference_no,
        "title": this.product.title,
        "amount": this.product.amount,
        
        "year": this.YY_formatYear(this.product.year), 
        "description": stripHtmlTags(this.product.description),
        "bill_type": this.bill_dropdownItem.name,
        attachments: this.attachments,
        "status": this.status_dropdownItem.name,
        "status_id": this.status_dropdownItem.value,
        "mobile_no": this.product.mobile_no,
        "user_id": localStorage.getItem("id"),
        lang: this.$i18n.locale,

      }
      if (this.product._id) {
        details['id'] = this.product._id;

      }

      this.isLoadingModel = true;
      var promise = apis.billAddEdit(details);
      promise.then((response) => {
        this.isLoadingModel = false;
        //this.$swal({title:response.data.message , confirmButtonText: this.$t('ok')});
        if (response.data.status == true) {
          this.$toast.add({
            severity: "success",
            
            summary: this.$t('success'),
            // detail: "Please Enter Description",
            detail: response.data.message,
            life: 3000,
          });

        }
        else {
          this.$toast.add({
            severity: "error",
            summary: "error",
            // detail: "Please Enter Description",
            detail: response.data.message,
            life: 3000,
          });


        }
        this.productDialog = false;
        this.get_list();
        this.get_count();
      });
    },



    async search(count) {
      if (count == 2) {
        this.search_filter = "";
        this.bill_typedropdown = "";
      }
      await this.get_list();
      await this.get_count();
    },
    async onPage(event) {
      this.lazyParams = event;
      this.page_no = event.page + 1;
      this.limit = event.rows;
      // console.log(event);
      //  await this.get_count();
      if (this.totalRecords > 0) {
        this.get_list();
      }
    },
    get_count: function () {
      var data = {
        count: true,
        bill_type: this.bill_typedropdown.name,
        search: this.search_filter,
        user_id: localStorage.getItem("id"),
      };
      this.loading = true;
      var promise = apis.bill_list(data, this.id);
      promise.then((response) => {
        this.loading = false;
        console.log(response);
        this.totalRecords = response.data.count;
        if (this.totalRecords > 100) {
          this.PerPageOptions.push(this.totalRecords);
        }
        //alert(this.totalRecords);
      });
      console.log(data);
    },
    get_list: function () {
      var data = {
        limit: this.limit,
        page_no: this.page_no,
        count: false,
        bill_type: this.bill_typedropdown.name,
        search: this.search_filter,
        user_id: localStorage.getItem("id"),
      };
      this.loading = true;
      var promise = apis.bill_list(data, this.id);
      promise.then((response) => {
        this.loading = false;
        console.log(response);
        this.products = response.data.data;
        console.log(this.products[0]);
        if (this.columns.length == 0) {
          if (this.products.length > 0) {
            this.columns = Object.keys(this.products[0]);
          }
        }
      });
      console.log(data);
    },
    openNew() {
      // this.file_data = {
      //   filePath: filename,
      //   contentType: file.type,
      // };
      this.file="";
      this.isEditMode = false;
      this.Mode = 3;
      this.view = false;
      this.product = {};
      this.status_dropdownItem = "";
      this.title = "";
      this.description = "";
      this.reference_no = "";
      this.mobile_no = "";
      this.bill_dropdownItem = "";

      this.amount = "";
      this.year = "";
      this.bill_type = "";

      this.submitted = false;
      this.productDialog = true;
    },
  


    async editProduct(product) {
      this.view = false;
      this.isEditMode = true;
      this.Mode = 1;
      // this.file_attachment = "";
      this.file_attachment = "";
      this.product = { ...product };
      

      if (this.product.status) {
        this.status_dropdownItem = { name: this.product.status, value: this.product.status_id };
      }
        // console.log(this.status_dropdownItem );
        

      if (this.product.bill_type) {
        this.bill_dropdownItem = { name: this.product.bill_type, value: this.product.bill_type };
      }
      // console.log(this.bill_dropdownItem );

      this.productDialog = true;
    },
    viewProduct(product) {
      this.selectedProduct = { ...product }; // Store the selected product data
      this.viewDialog = true; // Open the dialog
    },
    // async viewProduct(product) {
    //   // this.file_attachment = "";
    //   this.Mode=2;
    //   this.file_attachment = "";
    //   this.product = { ...product };
    //   if (this.product.status) 
    //   {
    //       this.status_dropdownItem={ name: this.product.status, value:  this.product.status_id };
    //   }
    //   if (this.product.bill_type) 
    //   {
    //       this.bill_dropdownItem={ name: this.product.bill_type, value:  this.product.bill_type };
    //   }
    //  this.view=true;
    //   this.productDialog = true;
    // },
    confirmDeleteProduct(product) {
      this.product = product;
      this.deleteProductDialog = true;
    },
    deletefaq() {
      if (this.product._id) {
        var data = {

          "_id": this.product._id,
          lang: this.$i18n.locale
        };
        this.isLoadingModel = true;
        var promise = apis.deleteBill(data);
        promise.then((responseapi) => {
          this.isLoadingModel = false;
          if (responseapi.data.status == true) {

            this.$toast.add({
              severity: "success", // success, info, warn, error
              summary: this.$t('success'),
              detail: responseapi.data.message,
              life: 3000, // Toast disappears after 3 seconds
            });
            this.deleteProductDialog = false;

            this.get_list();
            this.get_count();
          } else {
            this.$swal("error to  Bill");
          }
        });
      }
    },
    async exportCSV() {
      this.limit = this.totalRecords;
      this.get_list();
      await this.$refs.dt.exportCSV();
    },
    initFilters() {
      this.filters = {
        global: { value: null, matchMode: FilterMatchMode.CONTAINS },
      };
    },
  },
  components: {},
};
</script>
<style scoped lang="scss">
.custom-container {
  padding: 15px;
  background: white;
  border-radius: 10px;
}

.label {
  padding: 5px 10px;
  border: 1px solid #ddd;
  background-color: #f0f0f0;
  border-radius: 5px;
  font-weight: bold;
}

.value {
  color: #555;
  font-size: 14px;
}

.preview-image {
  width: 120px;
  height: auto;
  border-radius: 8px;
  border: 1px solid #ddd;
  box-shadow: 2px 2px 8px rgba(0, 0, 0, 0.1);
  padding: 3px;
  background: white;
}

@import "../assets/demo/badges.scss";
</style>